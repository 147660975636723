import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import { EventBus } from '../mixins/events.js'

export default class extends Controller {
  connect() {
    const a = document.createElement('a');
    const href = this.element.href;

    a.href = href;
    a.className = this.element.className;
    a.setAttribute('v-on:click.prevent', 'openForm')
    a.innerHTML = this.element.innerHTML

    const vm = new Vue({
      el: this.element,
      template: a.outerHTML,
      methods: {
        openForm() {
          EventBus.$emit('openForm', href)
        }
      }
    })
  }
}
