import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const searchUrl = this.data.get('url');

    $(this.element).select2({
      minimumInputLength: 1,
      ajax: {
        url: searchUrl,
        dataType: 'json',
        delay: 250,
        results: function (data, page) {
          return {results: data};
        },
        processResults: function (data, params) {
          return {
            results: _.map(data, function(event){
              return {id: event.id, text: event.name + ' - ' + event.client};
            })
          }
        }
      }
    });
  }
}
