import '../styles/ag-grid.scss'

import axios from '../mixins/axiosDefaults'
import { Controller } from "stimulus"
import { Grid } from "@ag-grid-community/core"
import { ModuleRegistry } from "@ag-grid-community/core"
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model"
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'

import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';

LicenseManager.setLicenseKey("CompanyName=Easibuy, LLC,LicensedApplication=EASiBuy Platform,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-024649,ExpiryDate=19_March_2023_[v2]_MTY3OTE4NDAwMDAwMA==aa87e13ba218b3dadfa37b06b2e1d42a")
ModuleRegistry.registerModules([
  ClipboardModule,
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule
])

import {formatCurrency, formatPercent} from '../mixins/submissionColumns'

export default class extends Controller {

  getParams(params) {
    const org = this.data.get('org');
    let url = this.data.get('url');
    if (params.request.groupKeys.length) url = params.parentNode.data.url;
    return [url, {org: org}];
  }

  createDatasource(server) {
    return {
      // called by the grid when more rows are required
      getRows: params => {
        const [url, urlParams] = this.getParams(params);

        // get data for request from server
        axios.get(url, {params: urlParams})
          .catch(() => {
            params.fail();
          })
          .then(response => {
            params.success({
              rowData: response.data
            });
          })
      }
    };
  }

  createLink(href, text, sneaky) {
    let a = document.createElement('a');
    const linkText = document.createTextNode(text);
    a.appendChild(linkText);
    a.href = href;
    a.title = text;
    if (sneaky) a.className = 'sneaky'
    return a;
  }

  defaultGridOptions() {
    const group = this.data.get('group');
    let myDatasource = this.createDatasource();

    let textCols = [{
      field: 'name',
      headerName: 'Event Name',
      flex: 3,
      minWidth: 120,
      cellRenderer: params => {
        if (params.node.group) return null;
        const data = params.data;
        return this.createLink(data.url, data.name)
      }
    }, {
      field: 'client',
      headerName: 'Organization',
      flex: 2,
      minWidth: 120,
      cellRenderer: params => {
        if (params.node.group && !params.value) return null;
        return this.createLink(params.data.orgUrl, params.value, true);
      }
    }];
    if (this.data.has('hideClient')) textCols.pop();

    let statsCols = []
    if (!this.data.has('hideStats')) {
      statsCols = [{
        field: "savings",
        type: ['numericColumn', 'statsColumn'],
        flex: null,
        minWidth: null,
        width: 60,
        valueFormatter: params => formatPercent(params, 1)
      }, {
        field: "baseline",
        type: ['numericColumn', 'statsColumn']
      }, {
        field: "spend",
        type: ['numericColumn', 'statsColumn']
      }, {
        field: "saved",
        type: ['numericColumn', 'statsColumn']
      }];
    }


    return {
      context: {
        locale: 'en-US',
        currency: this.data.get('currency')
      },
      columnDefs: [
        ...textCols,
        ...statsCols
      ],
      autoGroupColumnDef: {
        field: 'closeDate',
        headerName: 'Category / Close Date'
      },
      defaultColDef: {
        resizable: true
      },
      columnTypes: {
        'statsColumn': {
          flex: 1,
          minWidth: 110,
          valueFormatter: params => formatCurrency(params, 0)
        },
      },
      rowModelType: 'serverSide',
      serverSideDatasource: myDatasource,
      domLayout: 'autoHeight',
      enableRangeSelection: true,
      copyHeadersToClipboard: true,
      isServerSideGroupOpenByDefault: params => {
        // If the Row Node is not a group, it returns undefined
        if (!group || !params.rowNode.getRoute()) return false;
        return params.rowNode.key === group;
      },
      getChildCount: data => data.childCount
    };
  }

  renderGrid(gridOptions) {
    new Grid(this.element, gridOptions);
  }

}
