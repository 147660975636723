import { Controller } from "stimulus"
import Trix from "trix"
import { DirectUploadController } from "./direct_upload_controller"

import "trix/dist/trix.css";

export default class extends Controller {
  static targets = [ "documentColumn", "stages", "responseField", "reminderField", "instructionsColumn", "attachmentsColumn", "uploadRow"]

  connect() {
    console.log('called form')
    Trix.config.attachments.preview.caption.name = false;
    Trix.config.attachments.preview.caption.size = false;

    timeTooltip()

    this.uploads = 0;
    document.addEventListener('trix-initialize', (e) => {
      this.updateTrixToolbarVisability()

      // Safari bug fix: shift-enter is broken
      e.target.addEventListener('keydown', function(e) {
        if (e.shiftKey && e.key == "Enter") {
          e.target.editor.recordUndoEntry('Shift+Enter');
          e.target.editor.insertHTML('<br><br>');
          e.preventDefault();
        }
      })
    });
    this.updateTrixToolbarVisability()
    document.addEventListener("trix-focus", this.updateTrixToolbarVisability);
    document.addEventListener("trix-blur", this.updateTrixToolbarVisability);
    document.addEventListener('trix-file-accept', this.validateImage);
    document.addEventListener('trix-attachment-add', (event) => {
      let file = event.attachment.file;
      if (file) {
        // this.uploadImage(event, file);
        this.startUpload(file, event.attachment);
      }
    });

    $('#tasks, #attachments')
    .tooltip({
      selector: '.child-action i',
      placement: 'auto right',
      html: true
    })
    .on('inserted.bs.tooltip', function(e) {
      if (e.target.classList.contains('icon-warning')) {
        var tooltip = $(e.target).data('bs.tooltip');
        tooltip.$tip.addClass('tooltip-notice');
      }
    });

    new window.DirectUpload();

    $('.sortable', this.element).sortable({
      handle: 'i.icon-grip',
      forcePlaceholderSize: true,
      items: '.form-child'
    })
    .on('sortupdate', (e, ui) => {
      $(e.target).children('.form-child').each(function(index) {
        $(this).find('input.position').val(index + 1)
      })
    })

    let tasks = $('#tasks');
    $('#new_task, #assign_form').on('click', function(e) {
      // Must replace [id] when adding multiple records
      let time = new Date().getTime();
      let regexp = new RegExp($(this).data('id'), 'g');
      tasks.append($(this).data('fields').replace(regexp, time));
      tasks.sortable('refresh').trigger('sortupdate');
      e.preventDefault()
    });
  }

  disconnect() {
    $('.sortable', this.element).sortable('destroy');
  }

  updateTrixToolbarVisability() {
    $("trix-editor").each(function (index, editorElement) {
      var toolbarElement = editorElement.toolbarElement;
      if (editorElement == document.activeElement) {
        $(toolbarElement).show();
      } else {
        // don't hide the toolbar if we've unfocused to focus on the link dialog.
        if (!toolbarElement.contains(document.activeElement)) {
          $(toolbarElement).hide();
        }
      }
    });
  }

  toggleDestroy(event) {
    this.findAncestor(event.target, 'form-child').classList.add('hidden')
    event.target.previousElementSibling.value = "true";
  }

  findAncestor(el, cls) {
    while ((el = el.parentNode) && el.className.indexOf(cls) < 0);
    return el;
  }

  toggleStage(event) {
    document.getElementById('document_package_required_access_level').disabled = event.target.value !== 'bidders';
    this.stagesTarget.classList.toggle('hidden', event.target.value !== 'bidders');
  }

  toggleResponse(event) {
    this.responseFieldTargets.forEach(f => f.classList.toggle('hidden', !event.target.checked))
    this.instructionsColumnTarget.classList.toggle('col-md-8', !event.target.checked);
    this.attachmentsColumnTarget.classList.toggle('col-md-4', !event.target.checked);
    this.documentColumnTarget.classList.toggle('col-md-12', !event.target.checked)
    this.documentColumnTarget.classList.toggle('col-md-6', event.target.checked)
  }

  toggleUploads(event) {
    this.uploadRowTarget.classList.toggle('hidden', !event.target.checked);
    this.findAncestor(event.target, 'upload-placeholder').classList.toggle('disabled', !event.target.checked);
  }

  toggleReminder(event) {
    this.reminderFieldTarget.classList.toggle('hidden', !event.target.checked);
    if (!event.target.checked) document.getElementById("document_package_send_reminder_at")._flatpickr.clear();
  }

  validateImage(event) {
    let file = event.file;
    if (file) {
      if (!_.contains(['image/gif', 'image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'], file.type)) {
        console.log('rejected non image upload')
        event.preventDefault()
      }
    }
  }

  startUpload(file, attachment) {
    this.uploads++;
    this.disableSave();
    let upload = new DirectUploadController(file, event.attachment);
    upload.start(error => {
      if (error) {
        callback(error)
      } else {
        this.uploads--;
        this.enableSave();
      }
    })
  }

  disableSave() {
    Rails.disableElement(document.getElementById('submit_button'))
  }

  enableSave() {
    if (this.uploads === 0) {
      Rails.enableElement(document.getElementById('submit_button'))
    }
  }
}
