<template>
  <ul class="dropdown-menu dropdown-menu-right">
    <template v-for="(bidders, stage, index) in groupedResponses">
      <li class="divider" v-if="index > 0" />
      <li class="dropdown-header">{{capitalize(stage)}}</li>
      <li v-for="bidder in bidders">
        <a :href="bidder.url" @click.prevent="openForm(bidder)">
          <i
            class="fal fa-fw fa-lg"
            :class="{
              'fa-envelope-open': bidder.state === 'empty',
              'fa-envelope-open-text': bidder.state === 'started',
              'fa-envelope': bidder.state === 'submitted'
            }"
          />
          <span class="fa5-text">{{bidder.organizationName}}</span>
          <small>{{bidder.name}}</small>
          <i class="fas fa-flag-alt fa-sm" style="color: #d9534f; margin-left: 6px;" v-if="bidder.flagged"/>
        </a>
      </li>
    </template>
  </ul>
</template>

<script>
  import { EventBus } from '../mixins/events.js'

  export default {
    props: {
      bidders: {
        type: Array,
        required: true
      }
    },
    computed: {
      groupedResponses() {
        // Cannot rely on object order
        const stages = _.uniq(this.bidders.map(b => b.stage));

        const groupedResponses = {};
        stages.forEach(s => {
          const bidders = this.bidders.filter(r => r.stage === s);
          groupedResponses[s] = bidders;
        });
        return groupedResponses;
      }
    },
    methods: {
      capitalize(text) {
        return text.charAt(0).toUpperCase() + text.slice(1)
      },
      openForm(response) {
        console.log('openForm', response)
        EventBus.$emit('openForm', response.url)
      }
    }
  }
</script>
