<template>
  <div class="empty_message" v-if="loading" style="font-size: 1.2em">Loading...</div>

  <div class="empty_message" v-else-if="forms.length === 0">
    No form have been added yet. Get stated by building a
    <a :href="url.slice(0,-5) + '/new'">new form</a>
  </div>

  <table class="table" v-else>
    <thead>
      <tr>
        <td>Name</td>
        <td>Document</td>
        <td style="width: 24px"></td>
        <td>Due</td>
        <td>Instructions</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="form in forms">
        <td><a :href="form.formUrl">{{ form.name }}</a></td>
        <td>
          <a class="sneaky" :href="form.documentUrl" v-if="form.document">{{ form.document }}</a>
          <tooltip v-else text="Assign via tasks in a document" placement="right">
            <span class="empty">Unassigned</span>
          </tooltip>
        </td>
        <td><tooltip text="Document Closed" placement="left" v-if="closed(form.dueAt)"><i class="fas fa-lock"/></tooltip></td>
        <td>{{form.dueAt | moment("calendar", null, {sameElse: calendarElse})}}</td>
        <td>{{ form.instructions }}</td>
        <td style="text-align: right">
          <div class="btn-group" v-if="editor">
            <a class="btn btn-default" :href="form.formUrl"><i class="fal fa-envelope-open-text fa-lg"></i><span class="fa5-text fa-lg">Form Editor</span></a>
          </div>

          <div class="btn-group">
            <a
              class="btn btn-default"
              :href="form.formUrl + '/answers'"
              :class="{disabled: !form.bidders}"
            >
              <i class="fal fa-table fa-lg"></i><span class="fa5-text fa-lg">Side-by-Side</span>
            </a>
            <button
              type="button"
              class="btn btn-default dropdown-toggle"
              aria-expanded="false"
              data-toggle="dropdown"
              :disabled="!form.bidders"
            >
              <span class="caret"></span>
            </button>
            <form-bidder-menu :bidders="form.bidders" v-if="form.bidders" />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import dataFetcher from '../mixins/dataFetcher'
  import FormBidderMenu from '../components/FormBidderMenu'

  export default {
    mixins: [dataFetcher],
    components: {FormBidderMenu},
    props: {
      url: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        forms: [],
        editor: false
      }
    },
    created() {
      this.calendarElse = function(now) {
         if (this.isBefore(now)) {
            return 'MMM Do YYYY, h:mma';
          } else {
            return 'ddd, MMM Do, h:mma';
          }
      }
    },
    methods: {
      loadData(response) {
        const { data } = response;

        this.forms = data.rows
        this.editor = data.meta.editor
      },
      closed(dueAt) {
        return this.$moment(dueAt).isBefore(moment())
      }
    }
  }
</script>

<style lang="scss" scoped>
  span.empty {
    color: #999;
  }

  h1 {
    font-size: 3em
  }

  .fa-lock {
    color: #999
  }
</style>
