import axios from './axiosDefaults'

export default {
  data: function() {
    return {
      loading: true
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      return axios.get(this.url)
        .then(this.loadData)
        .then(() => { this.loading = false } )
        .catch(this.defaultErrorHandler);
    },
    loadData(response) {
      console.error('Override loadData method in component');
    },
    defaultErrorHandler(error) {
      console.log(error)
       if (error.response.status === 401) {
        window.location = '/' // Not authenticated. Return to root
       } else {
        this.errorMessage = 'Please try again. If you continue to see this error contact us and we\'ll get it worked out.';
        this.hasError = true;
       }
    }
  }
}
