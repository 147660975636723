import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import FormList from '../components/FormList'

export default class extends Controller {
  connect() {
    let url = this.data.get('url');
    let emptyMessage = this.data.get('emptyMessage');

    const vm = new Vue({
      el: this.element,
      template: '<form-list :url="url" :empty-message="emptyMessage"/>',
      components: {
        FormList
      },
      beforeMount() {
        this.url = url;
        this.emptyMessage = emptyMessage;
      }
    })
  }
}
